import { Organization_Configuration } from "@/sdk";
import { defineStore } from "pinia";

/**
 * Represents the state of the OrderStore.
 */
export type OrderStoreStateType = {
	currentOrder: any;
	currentOrderType: string;
	allOrders: any[];
	dispensedQty: number;
	totalQty: number | null;
	completedAssetIds: string[]; // The list of assets that have been filled already
	partiallyFilledAssetsIds: string[];
	currentOrderAssets: any[];
	hseCompleted: boolean;
	isCollectOdometerReading: boolean;
	currentAssetForDispense: any;
	lastTotalizerAfterAsTotalizerBefore: number;
	totalizerBeforeReading: number;
	totalizerAfterReading: number;
	totalizerBeforeDuringFirstAssetDispense: number | null;
	driverVehicleDetails: any;
	fuelDispensedTillNow: number;
	quantityToBeDispensed: number;
};

const initialState: OrderStoreStateType = {
	currentOrder: null,
	currentOrderType: "normal",
	allOrders: [],
	dispensedQty: 0,
	totalQty: 0,
	completedAssetIds: [],
	partiallyFilledAssetsIds: [],
	currentOrderAssets: [],
	hseCompleted: false,
	isCollectOdometerReading: false,
	currentAssetForDispense: [],
	lastTotalizerAfterAsTotalizerBefore: 0,
	totalizerBeforeReading: 0,
	totalizerAfterReading: 0,
	totalizerBeforeDuringFirstAssetDispense: null,
	driverVehicleDetails: [],
	fuelDispensedTillNow: 0,
	quantityToBeDispensed: 0,
};

export const useOrderStore = defineStore("orderStore", {
	state: (): OrderStoreStateType => ({
		...initialState,
	}),

  getters: {
    getCurrentOrder: (state) => state.currentOrder,
    getCurrentOrderType: (state) => state.currentOrderType,
    getAllOrders: (state) => state.allOrders,
    getDispensedQty: (state) => state.dispensedQty,
    getTotalQty: (state) => state.totalQty,
    getCompletedAssetIds: (state) => state.completedAssetIds,
	    getPartiallyFilledAssetsIds: (state) => state.partiallyFilledAssetsIds,
    getCurrentOrderAssets: (state) => state.currentOrderAssets,
    getHSECompleted: (state) => state.hseCompleted,
    getIsCollectOdometerReading: (state) => state.isCollectOdometerReading,
    getCurrentAssetForDispense: (state) => state.currentAssetForDispense,
    getLastTotalizerAfterAsTotalizerBefore: (state) => state.lastTotalizerAfterAsTotalizerBefore,
    getTotalizerBeforeReading: (state) => state.totalizerBeforeReading,
    getTotalizerAfterReading: (state) => state.totalizerAfterReading,
    getTotalizerBeforeDuringFirstAssetDispense: (state) => state.totalizerBeforeDuringFirstAssetDispense,
    getDriverVehicleDetails: (state) => state.driverVehicleDetails,
    getFuelDispensedTillNow: (state) => state.fuelDispensedTillNow,
    getQuantityToBeDispensed: (state) => state.quantityToBeDispensed,
    getIncompleteOrders: (state) => state.currentOrder,
  },
  

	actions: {
		/**
		 * Sets the current order.
		 * @param order - The current order.
		 */
		setCurrentOrder(order: any) {
			if (order === null) {
				this.currentOrder = null;
				this.setHSECompleted(false);
				return;
			}
			this.currentOrder = order;
			const safetyChecklist =
				order.customer_order?.customer_order_items[0]
					?.customer_order_item_safety_checklist_results;
			if (safetyChecklist) {
				this.setHSECompleted(safetyChecklist?.length > 0);
			} else {
				this.setHSECompleted(false);
			}
			return this.currentOrder;
		},

		/**
		 * Sets the current order type.
		 * @param orderType - The current order type.
		 */
		setCurrentOrderType(orderType: string) {
			this.currentOrderType = orderType;
		},

		/**
		 * Add a new order in allOrders at end of array
		 * @param order - Details of the order
		 */
		addOrder(order: any) {
			this.allOrders.push(order);
		},

		/**
		 * Remove the order from allOrders array
		 * @param order - Details of the order
		 */
		removeOrder(orderCode: string) {
			this.allOrders = this.allOrders.filter(
				(item) => item.order_code !== orderCode,
			);
		},

		/**
		 * Sets the all orders.
		 * @param orders - The all orders.
		 */
		setAllOrders(orders: any[]) {
			this.allOrders = orders;
		},

		/**
		 * Sets the total quantity.
		 * @param qty - The total quantity.
		 */
		setTotalQty(qty: number) {
			this.totalQty = Number(qty.toFixed(2));
		},

		/**
		 * Sets the fuel dispensed till now.
		 * @param qty - The fuel dispensed quantity.
		 */
		setDispensedQuantity(qty: number) {
			this.dispensedQty = Number(qty.toFixed(2));
		},

		/**
		 * Add the asset id to the list of completed assets
		 *
		 * @returns array of strings
		 */
		addCompletedAssetId(assetId: string): string[] {
			this.completedAssetIds.push(assetId);
			return this.completedAssetIds;
		},

		/**
		 * Remove the asset id from the list of completed assets
		 *
		 * @returns array of strings
		 */
		removeCompletedAssetId(assetId: string): string[] {
			this.completedAssetIds = this.completedAssetIds.filter(
				(id) => id !== assetId,
			);
			return this.completedAssetIds;
		},

		/**
		 * Reset the completed asset ids
		 */
		resetCompletedAssets(): void {
			this.completedAssetIds = [];
		},

		/**
		 * Sets the partially filled assets IDs.
		 * @param ids - The partially filled assets IDs.
		 */
		setPartiallyFilledAssetsIds(ids: string[]) {
			this.partiallyFilledAssetsIds = ids;
		},

		/**
		 * Set the HSE completed status
		 * @param status - The HSE completed status
		 * @returns The HSE completed status
		 */
		setHSECompleted(status: boolean) {
			this.hseCompleted = status;
			return this.hseCompleted;
		},

 /**
     * Sets multiple incomplete orders and updates related state
     * @param orders - Array of incomplete orders
     */
 setIncompleteOrders(orders: any[]) {
	if (!orders || !Array.isArray(orders)) {
		this.setCurrentOrder(null);
		this.setCurrentOrderType("normal");
		this.setTotalQty(0);
		this.setDispensedQuantity(0);
		return;
	}
	// Set the first incomplete order as the current order if available
	if (orders.length > 0) {
		this.setCurrentOrder(orders);
		console.log("Setting current order as incomplete order:", orders);
		this.setCurrentOrderType("incomplete");
  
		// Calculate total dispensed quantity across all orders
		let totalDispensedQty = 0;
		orders.forEach(order => {
			order?.customer_order?.customer_order_customer_assets?.forEach((obj: { quantity_dispensed: number; }) => {
				if (obj?.quantity_dispensed) {
					totalDispensedQty += obj.quantity_dispensed;
				}
			});
		});
		this.setDispensedQuantity(totalDispensedQty);
	}
  },
  


		/**
		 * Sets the list of orders as upcoming orders
		 *
		 * @param orders - The upcoming orders.
		 */
		setUpcomingOrders(
			orders: any[] | undefined,
			resetStore: boolean = false,
		) {
			if (typeof orders !== "undefined" && orders !== null) {
				this.setAllOrders(orders);
			}
			if (resetStore) {
				this.setCurrentOrder(null);
				this.setCurrentOrderType("normal");
				this.setTotalQty(0);
				this.setDispensedQuantity(0);
				this.setPartiallyFilledAssetsIds([]);
			}
			return this.allOrders;
		},

		/**
		 * Sets the current order assets.
		 * @param assets - The current order assets.
		 * @returns The current order assets.
		 */
		setCurrentOrderAssets(assets?: any[]) {
			assets = assets?.slice().sort((a,b)=>{
				if (!a.quantity_dispensed && b.quantity_dispensed) {
					return -1;
				} else if (a.quantity_dispensed && !b.quantity_dispensed) {
					return 1;
				} else {
					return 0;
				}
			});

			if (typeof assets !== "undefined" && assets !== null) {
				this.currentOrderAssets = assets;
			}
			return this.currentOrderAssets;
		},

		/**
		 * Sets whether to collect odometer reading.
		 * @param isCollectOdometerReading - The status of collecting odometer reading.
		 */
		setIsCollectOdometerReading(
			config: Organization_Configuration[],
		) {
			const [obj] = config.filter(
				(item) => item.key === "collect_odometer_reading",
			);
			if (obj) {
				this.isCollectOdometerReading = obj.value === "true";
			} else {
				this.isCollectOdometerReading = false;
			}
		},

		setCurrentAssetForDispense(order: any) {
			this.currentAssetForDispense = order;
			return this.currentAssetForDispense;
		},

		/**
		 * Sets the lastTotalizerAfter as totalizerBefore.
		 * @param totalizerBefore - The totalizerBefore.
		 * @param totalizerAfter - The totalizer after.
		 */
		setLastTotalizerAfterAsTotalizerBefore(totalizerBefore: number) {
			this.lastTotalizerAfterAsTotalizerBefore = totalizerBefore;
			return this.lastTotalizerAfterAsTotalizerBefore;
		},

		/**
		 * Sets the totalizer before reading.
		 * @param totalizerBefore - The totalizer before.
		 */
		setTotalizerBeforeReading(totalizerBefore: number) {
			this.totalizerBeforeReading = totalizerBefore;
			return this.totalizerBeforeReading;
		},

		/**
		 * Sets the totalizer before during first asset dispense.
		 * @param totalizerBefore - The totalizer before.
		 */
		setTotalizerBeforeDuringFirstAssetDispense(
			totalizerBefore: number | null,
		) {
			this.totalizerBeforeDuringFirstAssetDispense = totalizerBefore;
			return this.totalizerBeforeDuringFirstAssetDispense;
		},

		setDriverVehicleDetails(driverDetails: any) {
			this.driverVehicleDetails = driverDetails;
			return this.driverVehicleDetails;
		},

		/**
		 * Sets the totalizer after reading.
		 * @param totalizerAfter - The totalizer after.
		 */
		setTotalizerAfterReading(totalizerAfter: number) {
			this.totalizerAfterReading = totalizerAfter;
			return this.totalizerAfterReading;
		},

		setFuelDispensedTillNow(fuelDispensedTillNow: number) {
			this.fuelDispensedTillNow = fuelDispensedTillNow;
			return this.fuelDispensedTillNow;
		},

		setQuantityToBeDispensed(qty: number) {
			this.quantityToBeDispensed = qty;
			return this.quantityToBeDispensed;
		},

		resetStore() {
			Object.assign(this, initialState);
		},
	},
	persist: true,
});
